import { HeroSection } from '../components/HomePage/HomePage.types';

import { SEARCH_URLS, HOMEPAGE_URLS } from '../types';
import { SECTIONS } from '../types';
import { HomepageSEOProps } from '../types';

export const mockEndpoints: { [key: string]: string } = {
  DAFT_API_GATEWAY: 'http://localhost:5001',
  SEARCH_SERVICE: 'http://localhost:5001',
  DAFT_METADATA_SERVICE: 'http://localhost:5001',
};

export const TERMS_OF_USE_URL = `https://support.daft.ie/hc/en-ie/articles/5127313728273-Terms-and-Conditions-of-use-of-Daft`;

const OpenCookieConsentModal = () => {
  if (typeof window !== 'undefined') {
    (window as any).Didomi.preferences.show();
  }
};

export const ResidentialSearchHeroSections: HeroSection[] = [
  {
    name: 'Buy',
    url: `/${SEARCH_URLS.BUY}`,
    pageRoute: '/',
    section: SECTIONS.BUY,
  },
  {
    name: 'Rent',
    url: `/${SEARCH_URLS.RENT}`,
    pageRoute: `/${HOMEPAGE_URLS.RENT}`,
    section: SECTIONS.RENT,
  },
  {
    name: 'Share',
    url: `/${SEARCH_URLS.SHARE}`,
    pageRoute: `/${HOMEPAGE_URLS.SHARE}`,
    section: SECTIONS.SHARE,
  },
  {
    name: 'Sell',
    url: `${HOMEPAGE_URLS.SELL}`,
    pageRoute: `${HOMEPAGE_URLS.SELL}`,
    section: 'Sell' as SECTIONS, //sitemap is generated for all SECTIONS elements. Wix pages should not be part of it
    isExternalLink: true,
  },
];

export const CommercialSearchHeroSections: HeroSection[] = [
  {
    name: 'To Lease',
    url: `/${SEARCH_URLS.COMMERCIAL_RENT}`,
    section: SECTIONS.COMMERCIAL_RENT,
  },
  {
    name: 'For Sale',
    url: `/${SEARCH_URLS.COMMERCIAL_BUY}`,
    section: SECTIONS.COMMERCIAL_BUY,
  },
];

export const StudentAccommodationSearchHeroSections: HeroSection[] = [
  {
    name: 'For Rent',
    url: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_RENT}`,
    section: SECTIONS.STUDENT_ACCOMMODATION_RENT,
  },
  {
    name: 'To Share',
    url: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE}`,
    section: SECTIONS.STUDENT_ACCOMMODATION_SHARE,
  },
];

export const HomepageSEOConfig: HomepageSEOProps = {
  '/': {
    title: 'Buy',
    tagline: 'Find your way home',
    metaTitle: "Search Ireland's No. 1 Property Website | Daft.ie",
    metaDescription:
      'Search 1000s of houses, apartments and properties for sale or to rent - from all leading estate agents',
    ogImage: 'static/images/meta/meta-buy-homepage.jpg',
    backgroundImage: {
      desktop: 'static/images/backgrounds/homepage-hero-buy-desktop',
      mobile: 'static/images/backgrounds/homepage-hero-buy-mobile',
    },
  },
  '/homes-to-rent': {
    title: 'Rent',
    tagline: 'Rent houses & apartments',
    metaTitle: 'Search houses and apartments to rent in Ireland | Daft.ie',
    metaDescription:
      'Find 1000s of properties and lettings in Ireland on Daft.ie - the Largest Property Listings Website in Ireland.',
    ogImage: 'static/images/meta/meta-rent-homepage.jpg',
    backgroundImage: {
      desktop: 'static/images/backgrounds/homepage-hero-rent-desktop',
      mobile: 'static/images/backgrounds/homepage-hero-rent-mobile',
    },
  },
  '/rooms-to-rent': {
    title: 'Share',
    tagline: 'The easy way to house share',
    metaTitle: 'Ireland Rooms to Share, House Shares and Roommates | Daft.ie',
    metaDescription:
      "Find your ideal room to rent from a selection of house shares in Ireland on Daft.ie - Ireland's Largest Property Listings Website.",
    ogImage: 'static/images/meta/meta-share-homepage.jpg',
    backgroundImage: {
      desktop: 'static/images/backgrounds/homepage-hero-share-desktop',
      mobile: 'static/images/backgrounds/homepage-hero-share-mobile',
    },
  },
  '/new-build': {
    title: 'New Homes',
    tagline: 'New home. New start.',
    metaTitle: 'New homes for sale in Ireland | Daft.ie',
    metaDescription:
      'Find new homes for sale from every major development across Ireland. 1000s of new builds on Daft.ie',
    ogImage: 'static/images/meta/meta-newhomes-homepage.jpg',
    backgroundImage: {
      desktop: 'static/images/backgrounds/homepage-hero-newhomes-desktop',
      mobile: 'static/images/backgrounds/homepage-hero-newhomes-mobile',
    },
  },
};

export const FooterNavLinkConfig = (BASE_URL = 'https://daft.ie') => [
  {
    name: 'About us',
    url: 'https://support.daft.ie/hc/en-ie/articles/360007696318-About',
  },
  {
    name: 'Terms of use',
    url: TERMS_OF_USE_URL,
  },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Equality',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003993337-Property-Advertisement-Equality-Guidelines',
  },
  {
    name: 'Data requests',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003993577-GDPR-Requests',
  },
  {
    name: 'Remembering Carolan',
    url: 'https://www.brand.daft.ie/remembering-carolan',
  },
  {
    name: 'Advertising',
    url: 'https://www.agentproducts.daft.ie/advertising-promote-my-brand',
  },
  {
    name: 'Help centre',
    url: 'https://support.daft.ie/',
  },
  {
    name: 'Buying Budget Calculator',
    url: `${BASE_URL}/daft-mortgages/buying-budget`,
    openInNew: true,
  },
];

export const VTFooterNavLinkConfig = (BASE_URL: string) => [
  { name: 'How it works', url: '/agents/vt-how-it-works' },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Logout',
    url: `${BASE_URL}/agents/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agents/valuation-tool`,
  },
];

export const VTFooterNavLinkConfigV2 = (BASE_URL: string) => [
  { name: 'How it works', url: '/agent-v2/vt-how-it-works' },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Logout',
    url: `${BASE_URL}/agent-v2/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agent-v2/valuation-tool`,
  },
];

export const OffersFooterNavLinkConfig = (BASE_URL: string) => [
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Logout',
    url: `${BASE_URL}/agents/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agents/offers/residential-for-sale`,
  },
];

export const OffersFooterNavLinkConfigV2 = (BASE_URL: string) => [
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Logout',
    url: `${BASE_URL}/agent-v2/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agent-v2/offers/residential-for-sale`,
  },
];

export const DataHubNavLinkConfig = (BASE_URL: string) => [
  {
    name: 'Terms of use',
    url: 'https://support.daft.ie/hc/en-ie/articles/5127313728273-Terms-and-Conditions-of-use-of-Daft',
  },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Sign out',
    url: `${BASE_URL}/agents/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agents/datahub`,
  },
];

export const DataHubNavLinkConfig_v2 = (BASE_URL: string) => [
  {
    name: 'Terms of use',
    url: 'https://support.daft.ie/hc/en-ie/articles/5127313728273-Terms-and-Conditions-of-use-of-Daft',
  },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Cookie settings',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Sign out',
    url: `${BASE_URL}/agent-v2/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agent-v2/datahub`,
  },
];

export const IntelToolFooterNavLinkConfig = (BASE_URL: string) => [
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Logout',
    url: `${BASE_URL}/agents/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agents/bi/supply`,
  },
];

export const IntelToolFooterNavLinkConfigV2 = (BASE_URL: string) => [
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Logout',
    url: `${BASE_URL}/agent-v2/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agent-v2/bi/supply`,
  },
];

export const RequestValuationFooterNavLinkConfig = [
  {
    name: 'About us',
    url: 'https://support.daft.ie/hc/en-ie/articles/360007696318-About',
  },
  {
    name: 'Terms of use',
    url: TERMS_OF_USE_URL,
  },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Equality',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003993337-Property-Advertisement-Equality-Guidelines',
  },
  {
    name: 'Data requests',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003993577-GDPR-Requests',
  },
  {
    name: 'Help centre',
    url: 'https://support.daft.ie/',
  },
];

export const AdPerformanceToolFooterNavLinkConfig = (BASE_URL: string) => [
  {
    name: 'Terms of use',
    url: TERMS_OF_USE_URL,
  },
  {
    name: 'Privacy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies',
  },
  {
    name: 'Cookie policy',
    url: 'https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies#cookies',
  },
  {
    name: 'Consent choices',
    onClick: OpenCookieConsentModal,
  },
  {
    name: 'Sign out',
    url: `${BASE_URL}/agents/auth/logout?redirectBackToAfterLogOut=${BASE_URL}/agents/adperformance/mur
    `,
  },
];

export const AdTypeConfig: { [key: string]: string } = {
  rental: 'Residential Rent',
  commercial: 'Commercial',
  sharing: 'Sharing',
  holiday_home: 'Holiday Homes',
  international_rental: 'Overseas Rent',
  parking: 'Parking',
  sale: 'Residential Sale',
  international_sale: 'Overseas Sale',
  new_development: '',
  mur: '',
};
